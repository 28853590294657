export const periods = ["2022", "2021", "2020", "2019", "2018", "2017", "2016"];

export function previousPeriod(period: string) {
    return ((Number.parseInt(period, 10)) - 1).toString();
}

export const personalPreparationTimesMap: Map<string, number> = new Map<string, number>([
    ["P_7_10", 15],
    ["P_5_6", 35],
    ["P_2_4", 50],
    ["P_36", 110]
]);

export const businessPreparationTimesMap: Map<string, number> = new Map<string, number>([
    ["P_7_14", 25],
    ["P_5_6", 100],
    ["P_2_4", 125],
    ["P_36", 250]
]);

export enum BusinessTaxReturnType {
    All, FEDERAL, STATE, CITY
}

