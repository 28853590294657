import {BusinessTaxReturnType} from "@/constants/TaxationConstants";

export default class UploadBusinessTaxationRequest {
    public companyId: number | null = null;

    public businessTaxReturnFileMetaId: number | null = null;

    public type: BusinessTaxReturnType | null = null;

    public period: string | null = null;

    public state: string | null = null;
}