











































































import {Component, Prop, Vue} from "vue-property-decorator";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {namespace} from "vuex-class";
import Workspace from "@/dto/auth/Workspace";
import {documentAccept} from "@/constants/FileConstants";
import UploadBusinessTaxationRequest from "@/dto/taxation/business/UploadBusinessTaxationRequest";
import {BusinessTaxReturnType} from "@/constants/TaxationConstants";
import FileUpload from "@/components/common/FileUpload.vue";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {STATE_LIST} from "@/constants/ProfileConstants";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {FileUpload}
})
export default class BusinessTaxReturnModal extends Vue{
    private accept = documentAccept;

    private states = STATE_LIST;

    private periods = ["2023", "2022", "2021", "2020", "2019", "2018", "2017"];

    private types = ["ALL", "FEDERAL", "STATE", "CITY"];

    private request: UploadBusinessTaxationRequest = new UploadBusinessTaxationRequest();

    private file: FileMetaDTO | null = null;

    private message = "";

    private successful = false;

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    @Prop()
    private onSave!: (period: string) => void

    mounted() {
        this.request.type = BusinessTaxReturnType.All;
        this.request.companyId = Workspaces.getCurrent.id;
    }

    closeModal(){
        this.$modal.hideAll();
    }

    errorHandle(error: any) {
        this.successful = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.stopLoading()
    }

    save() {
        this.$validator.validate().then(
            isValid => {
                if (isValid) {
                    this.startLoading()
                    this.message = "";
                    this.request.businessTaxReturnFileMetaId = this.file?.id as number;
                    CompanyService.uploadBusinessTaxReturn(this.request).then(
                        () => {
                            this.onSave(this.request.period as string);
                            this.stopLoading();
                            this.closeModal();
                        },
                        err => this.errorHandle(err)
                    )
                }
            }
        )
    }

    handleLabel(): string {
      return (!this.file ? "Upload" : "Change") + " business tax return";
    }

    warningHandle() {
      this.successful = false;
      this.message = "The business tax returns should be attached ";
    }

    onFileLoaded(files: FileMetaDTO[]){
        if(!files.length){
            return;
        }
        this.file = files[0];
    }
}
